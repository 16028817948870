import React, { } from "react";

import { Container, Row } from "reactstrap";

import { Breadcrumbs } from "@ui/components/Common";
import { withTranslation } from "react-i18next";

interface DashboardProps {
  t: any;
}

const Dashboard: React.FC<DashboardProps> = ({ t }) => {

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("Dashboard")} breadcrumbItem={t("Dashboard subtitle")} />

        <Row className="mt-4">
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(Dashboard);
