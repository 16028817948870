import { Redirect } from "react-router-dom";

import { AppRoutes } from "@domain/enums";

import Dashboard from "@main/pages/reports/Dashboard";
import UserList from "@main/pages/users/UsersList";
import RevenuesAndExpenses from "@main/pages/revenues-expenses/RevenuesAndExpenses";
import Groups from "@main/pages/groups/Groups";
import ProviderList from "@main/pages/provider/ProviderList";
import BankAccount from "@main/pages/accounts/BankAccount";
import { Login, ForgotPassword, ForgotPasswordRecover } from "@main/pages/auth";
import Prefecture from "@main/pages/prefecture/Prefecture";
import Sources from "@main/pages/sources/Sources";
import { Planning } from "@main/pages/planning";
import { Treasury } from "@main/pages/treasury";
import EffectiveBills from "@main/pages/bills/EffectiveBills";
import NonEffectiveBills from "@main/pages/bills/NonEffectiveBills"; 

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  { path: AppRoutes.DASHBOARD, component: Dashboard },
  { path: AppRoutes.USERS, component: UserList },
  { path: AppRoutes.REVENUESANDEXPENSES, component: RevenuesAndExpenses },
  { path: AppRoutes.GROUPS, component: Groups },
  { path: AppRoutes.PROVIDERS, component: ProviderList },
  { path: AppRoutes.BANKACCOUNT, component: BankAccount },
  { path: AppRoutes.PREFECTURE, component: Prefecture },
  { path: AppRoutes.SOURCES, component: Sources },
  { path: AppRoutes.PLANNING, component: Planning },
  { path: AppRoutes.TREASURY, component: Treasury },
  { path: AppRoutes.EFFECTIVEBILLS + "/:type", component: EffectiveBills },
  { path: AppRoutes.NONEFFECTIVEBILLS + "/:type", component: NonEffectiveBills },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to={AppRoutes.DASHBOARD} />,
  },
];

const authRoutes: Array<RouteProps> = [
  { path: AppRoutes.LOGIN, component: Login },
  { path: AppRoutes.FORGOT_PASSWORD, component: ForgotPassword },
  {
    path: `${AppRoutes.FORGOT_PASSWORD_RECOVER}/:email`,
    component: ForgotPasswordRecover,
  },
];

export { userRoutes, authRoutes };
